// src/App.js
import React from 'react';
import MainFrame from './MainFrame';
import './App.css';

function App() {
  return (
    <div className="App">
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <MainFrame />
    </div>
  );
}

export default App;
