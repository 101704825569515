const { isMobile } = require("react-device-detect");

function josa(word, josaType) {
    const lastChar = word.charAt(word.length - 1);
    const lastCharCode = lastChar.charCodeAt(0);
    
    // 한글의 유니코드 범위: 0xAC00 ~ 0xD7A3
    // 한글의 종성 여부는 (유니코드 - 0xAC00) % 28 값으로 판별 가능
    const hasFinalConsonant = (lastCharCode - 0xAC00) % 28 !== 0;

    switch (josaType) {
        case '은는':
            return (hasFinalConsonant ? '은' : '는');
        case '이가':
            return (hasFinalConsonant ? '이' : '가');
        case '와과':
            return (hasFinalConsonant ? '과' : '와');
        case '을를':
            return (hasFinalConsonant ? '을' : '를');
        default:
            return ''; // 입력한 조사 유형이 없을 경우, 그대로 반환
    }
}

const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('핫플 URL 링크가 클립보드에 복사되었어요!\n함께 가고 싶은 친구에게 공유해볼까요?');
    } catch (err) {
      console.error('클립보드 복사 실패: ', err);
    }
  };
  
  // 공유 기능을 호출하는 함수
  const shareContent = async ( title, text, url ) => {
    if (navigator.share && isMobile) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: url
        });
      } catch (err) {
        console.error('공유 실패: ', err);
      }
    } else {
      // 공유 기능을 사용할 수 없는 경우 클립보드에 복사
      await copyToClipboard(url);
    }
  };
  

module.exports = {
    josa,
    shareContent,
    copyToClipboard
};