import styles from './LocationBar.module.css';


const LocationBar = ({mapHotples, mapLocation, setIsSearchMode}) => {
  	return (
    		<div className={styles.frameParent} onClick={()=>setIsSearchMode(true)}>
      			<div className={styles.parent}>
					<div className={styles.div}>{mapLocation.name || mapLocation.address}</div>
					<img className={styles.iconsearch} alt="" src="images/search.svg" />
					<div className={styles.div1}>핫플 {mapHotples.hotples.length}개</div>
      			</div>
    		</div>);
};

export default LocationBar;
