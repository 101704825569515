import styles from './SearchHeader.module.css';
import React, { useState, useEffect } from 'react';
import {callSearchingPlaces} from '../../../api'
import { isMobileSafari } from 'react-device-detect';


const SearchHeader = ({selectedOption, setSelectedOption, setMapLocation, setIsSearchMode}) => {
	const regionList = ['전국','서울','경기','인천','강원','대구','경북','경남','부산','울산','전북','전남','광주','충북','충남','대전','제주','세종'];
    const [query, setQuery] = useState('');
    const [places, setPlaces] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);

	const whenInputUnfocused =() => {
		setTimeout(() => {
			setIsSearchMode(false);
		}, 100);
	}

    useEffect(() => {
        if (query) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            const id = setTimeout(() => {
                fetchPlaces(query);
            }, 100);

            setTimeoutId(id);
        } else {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setPlaces([]);
        }

        return () => clearTimeout(timeoutId);
    }, [query]);

	useEffect(() => {
		if(isMobileSafari)	//iOS 모바일 사파리에서의 오류로 인한 분기처리
			document.getElementById('placeInput').focus();
		else{
			setTimeout(() => {
				const inputElement = document.getElementById('placeInput');
				if (inputElement) {
					inputElement.focus();
				}
			}, 0);	
		}
	}, []);

	const fetchPlaces = async (keyword) => {
        const results = await callSearchingPlaces(keyword);
        setPlaces(results);
    };

    const handlePlaceClick = (place) => {
		setSelectedOption(
		{...selectedOption,
			region: '위치'
		});
        setMapLocation({
            name: place.place_name,
            address: place.address_name.split(' ').slice(0, 3).join(' '),
            latitude: place.y,
            longitude: place.x,
        });
    };

  	return (
    		<div className={styles.searchheader}>
      			<div className={styles.searchframe}>
        				<div className={styles.div}>
                            <input
                                type="text"
                                id="placeInput"
                                className={styles.input}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
								onBlur={() => whenInputUnfocused()}
                                placeholder="장소 검색"
                                autoComplete="off"
                            />
        				</div>
        				<div className={styles.right}>
          					<div className={styles.div2}>
            						<img className={styles.iconsearch} alt="" src="images/search.svg" />
          					</div>
        				</div>
      			</div>
      			<div className={styles.searchheaderChild} />
      			<div className={styles.bottomframe}>
                    {query=='' &&
        				<div className={styles.regionbuttons}>
							{regionList.map(region => (
								<div key={region} className={`${selectedOption.region === region || (region=='전국'&&selectedOption.region=='위치') ? styles.regionButtonSelected : styles.regionButton}`} onClick={() => setSelectedOption((prev)=>({...prev, region: region}))}>
										<div className={styles.text}>{region}</div>
								</div>
							))}
        				</div>
                    }

                    {places.length > 0 && (
                        <>
                            {places.map((place) => (
                                <div className={styles.searchresult} key={place.id} onClick={() => handlePlaceClick(place)}>
                                    <div className={styles.leftinfo}>
                                        <div className={styles.placename}>{place.place_name}</div>
                                        <div className={styles.placeaddress}>{place.road_address_name || place.address_name}</div>
                                    </div>
                                    <div className={styles.category}>{place.category_name.split(/[,>]/).pop().trim()}</div>
                                </div>
                            ))}
                        </>
                    )}
      			</div>
    		</div>);
};

export default SearchHeader;
