import styles from './UserModal.module.css';
import DeleteHotpleModal from './DeleteHotple/DeleteHotpleModal';


const UserModal = ({userModalPage, setUserModalPage, modalProps, deleteMyHotple}) => {
  	return (
        <div className={styles.modalbackground}>
            <div className={styles.modalcontainer}>
                {userModalPage==1 &&<DeleteHotpleModal setUserModalPage={setUserModalPage} modalProps={modalProps} deleteMyHotple={deleteMyHotple}/>}

                <div className={styles.closebutton} onClick={()=>setUserModalPage(0)}>
                    <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                </div>
            </div>
        </div>
    );
};
    
export default UserModal;
  