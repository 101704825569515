import { useRef, useEffect } from 'react';
import { MIN_Y, MAX_Y, MID_Y } from './MobileBottomSheetOption';
import { isAndroid } from 'react-device-detect';

interface BottomSheetMetrics {
  touchStart: {  
    sheetY: number;    
    touchY: number;    
  };
  touchMove: {         
    prevTouchY?: number; 
    movingDirection: "none" | "down" | "up"; 
  };
  isContentAreaTouched: boolean; 
}

interface MidY {
  value: number; 
}

export default function useBottomSheet(setSelectedHople) {
  const sheet = useRef<HTMLDivElement>(null); 
  const content = useRef<HTMLDivElement>(null);

  const metrics = useRef<BottomSheetMetrics>({   
    touchStart: {                                
      sheetY: 0,                                 
      touchY: 0,                                 
    },
    touchMove: {                             
      prevTouchY: 0,                         
      movingDirection: "none",                   
    },
    isContentAreaTouched: false           
  });

  const mid_y = useRef<MidY>({   
    value: 0           
  });

  useEffect(() => { 

    const canUserMoveBottomSheet = () => {     
      const { touchMove, isContentAreaTouched } = metrics.current;
      const tolerance = 1;
      const sheetY = sheet.current!.getBoundingClientRect().y;

      if (Math.abs(sheetY - MAX_Y) <= tolerance || Math.abs(sheetY - (mid_y.current.value==0 ? MID_Y : mid_y.current.value)) <= tolerance) {
        return true;
      }

      if (!isContentAreaTouched) {
        return true;
      }

      if (Math.abs(sheetY - MIN_Y) > tolerance) { 
        return true;
      }

      if (touchMove.movingDirection === 'down') { 
        return content.current!.scrollTop <= 0; 
      }
      return false;
    }

    const handleTouchStart = (e: TouchEvent) => {        
      const { touchStart } = metrics.current;           
      touchStart.sheetY = sheet.current!.getBoundingClientRect().y;       
      touchStart.touchY = e.touches[0].clientY;                          
    };

    const handleTouchMove = (e: TouchEvent) => {
      const { touchStart, touchMove } = metrics.current;                      
      const currentTouch = e.touches[0];

      if (touchMove.prevTouchY === undefined) {                                    
        touchMove.prevTouchY = touchStart.touchY;                             
      }
      
      if (touchMove.prevTouchY === 0) {
        touchMove.prevTouchY = touchStart.touchY;
      }

      if (touchMove.prevTouchY < currentTouch.clientY) {                      
        touchMove.movingDirection = 'down';                                   
      }

      if (touchMove.prevTouchY > currentTouch.clientY) {                      
        touchMove.movingDirection = 'up';                                    
      }

      if (document.activeElement && document.activeElement.tagName === 'INPUT') {
        document.activeElement.blur();
      }

      if (canUserMoveBottomSheet()) {        
        e.preventDefault(); 
  
        const touchOffset = currentTouch.clientY - touchStart.touchY; 
        let nextSheetY = touchStart.sheetY + touchOffset;

        if (nextSheetY <= MIN_Y) {
          nextSheetY = MIN_Y; 
        }

        if (nextSheetY >= MAX_Y) {
          nextSheetY = MAX_Y; 
        }

        sheet.current!.style.setProperty('transform', `translateY(${nextSheetY - MAX_Y}px)`);
      } else {
        document.body.style.overflowY = 'hidden';
      }
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const { touchMove } = metrics.current;           

      const currentSheetY = sheet.current!.getBoundingClientRect().y; 
      const contentScrollTop = content.current!.scrollTop;
      const isContentAtTop = contentScrollTop === 0;

      if (currentSheetY !== MIN_Y) { 
        if (touchMove.movingDirection === 'down') {
          if(isAndroid) {
            if(isContentAtTop){
              sheet.current!.style.setProperty('transform', 'translateY(0)');
              setSelectedHople(null);
            }
          }
          else{
            sheet.current!.style.setProperty('transform', 'translateY(0)');
            setSelectedHople(null);
          }
        }

        if (touchMove.movingDirection === 'up') { 
          sheet.current!.style.setProperty('transform', `translateY(${MIN_Y - MAX_Y}px)`); 
          setSelectedHople(null);
        }
      }

      metrics.current = {
        touchStart: {
          sheetY: 0,
          touchY: 0,
        },
        touchMove: {
          prevTouchY: 0,
          movingDirection: "none",
        },
        isContentAreaTouched: false
      };
    }

    sheet.current!.addEventListener('touchstart', handleTouchStart); 
    sheet.current!.addEventListener('touchmove', handleTouchMove);
    sheet.current!.addEventListener('touchend', handleTouchEnd);

    return () => {
      sheet.current!.removeEventListener('touchstart', handleTouchStart); 
      sheet.current!.removeEventListener('touchmove', handleTouchMove);
      sheet.current!.removeEventListener('touchend', handleTouchEnd);
    };

  }, [])

  useEffect(() => {
    const handleTouchStart = () => {
      metrics.current.isContentAreaTouched = true;
    }
    
    content.current!.addEventListener('touchstart', handleTouchStart);
    
    return () => content.current!.removeEventListener('touchstart', handleTouchStart);
  }, []);

  const setBottomSheetPosition = (position: "min" | "mid" | "max", content_height=0) => {
    let targetY;

    if(content_height!=0)
      mid_y.current.value=window.innerHeight-content_height-70;

    if (position === "min") {
      targetY = MAX_Y;  // BottomSheet가 닫혀있는 상태
    } else if (position === "mid") {
      targetY = (mid_y.current.value==0 ? MID_Y : mid_y.current.value);  // BottomSheet가 반쯤 펼쳐진 상태
    } else {
      targetY = MIN_Y;  // BottomSheet가 최대한 펼쳐진 상태
    }
    sheet.current!.style.setProperty('transform', `translateY(${targetY - MAX_Y}px)`);
  }

  return { sheet, content, setBottomSheetPosition }

}
