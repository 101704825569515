import { BrowserView, MobileView } from 'react-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import LoginHeader from '../Header/LoginHeader';
import UserHeader from '../Header/UserHeader';
import LocationBar from '../LocationBar/LocationBar';
import styles from './Contents.module.css';
import HotpleItem from './HotpleItem/HotpleItem';

const Contents = ({ activeMenu, setAuthModalPage, account, myHotples, mapHotples, updateHotpleInfo, mapLocation, setIsSearchMode, selectedHotple, setSelectedHotple, selectedOption, setSelectedOption}) => {
	const hotpleRefs = useRef({});

	useEffect(() => {
		// 선택된 Hotple이 있을 경우 스크롤 조정
		if (selectedHotple && hotpleRefs.current[selectedHotple.hotple_id]) {
		// 선택된 HotpleItem을 스크롤 최상단으로 이동
		hotpleRefs.current[selectedHotple.hotple_id].scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		}
	}, [selectedHotple]); // selectedHotple이 변경될 때마다 실행

	return (
		<div className={styles.frame}>
			<BrowserView className={styles.contents}>
				{account.hotpleId == null ? <LoginHeader setAuthModalPage={setAuthModalPage} /> : <UserHeader setAuthModalPage={setAuthModalPage} account={account} myHotples={myHotples}/>}
				<div className={styles.divider} />
				<LocationBar mapHotples={mapHotples} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode}/>
				<div className={styles.hotpleitems}>
					{activeMenu=='my' &&
						<div className={styles.filterbuttons}>
							<div className={selectedOption.filter == 'all' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'all'}))}>
								<div className={styles.label}>전체</div>
							</div>
							<div className={selectedOption.filter == 'need_verify' ? styles.needverifybuttonSelected : styles.needverifybutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'need_verify'}))}>
								<img className={styles.iconupdate} alt="" src="images/update.svg" />
								<div className={styles.label}>검토 필요</div>
							</div>
							<div className={selectedOption.filter == 'my_pick' ? styles.mypickbuttonSelected : styles.mypickbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'my_pick'}))}>
								<img className={styles.iconstar} alt="" src="images/star_filled.svg" />
								<div className={styles.label}>내 PICK</div>
							</div>
							<div className={selectedOption.filter == 'has_period' ? styles.periodbuttonSelected : styles.periodbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'has_period'}))}>
								<img className={styles.iconupdate} alt="" src="images/notification.svg" />
								<div className={styles.label}>기간</div>
							</div>
							{[...new Set(myHotples.hotples.flatMap(hotple => {
								const taggedByUsernames = hotple.taggedBy ? [hotple.taggedBy] : [];
								const commentUsernames = hotple.comment ? (hotple.comment.match(/(@[\w\.]+)/g) || []).map(username => username.substring(1)) : [];

								return [...taggedByUsernames, ...commentUsernames];
							}).filter(username => username && username !== account.instagramUsername))].map(username => (
								<div
									className={selectedOption.filter == username ? styles.usernamebuttonSelected : styles.usernamebutton}
									onClick={() => setSelectedOption(prev => ({ ...prev, filter: username }))}
									key={username}
								>
									<div className={styles.label4}>@{username}</div>
								</div>
							))}
						</div>
					}
					{mapHotples.hotples.map(hotple=>(
						<div
							key={hotple.hotple_id}
							ref={el => hotpleRefs.current[hotple.hotple_id] = el} // 각 HotpleItem에 대한 ref 할당
						>
							<HotpleItem key={hotple.hotple_id} hotple={hotple} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple} isSelected={selectedHotple && hotple.hotple_id==selectedHotple.hotple_id}/>
						</div>
					))}
				</div>
			</BrowserView>

			<MobileView className={styles.contents}>
				{selectedHotple==null && 
					<>
						{account.hotpleId == null ? <LoginHeader setAuthModalPage={setAuthModalPage} /> : <UserHeader setAuthModalPage={setAuthModalPage} account={account} myHotples={myHotples}/>}
						<div className={styles.divider} />
						<LocationBar mapHotples={mapHotples} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode}/>
					</>
				}
				<div className={styles.hotpleitems}>
					{activeMenu=='my' && selectedHotple == null &&
						<div className={styles.filterbuttons}>
							<div className={selectedOption.filter == 'all' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'all'}))}>
								<div className={styles.label}>전체</div>
							</div>
							<div className={selectedOption.filter == 'need_verify' ? styles.needverifybuttonSelected : styles.needverifybutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'need_verify'}))}>
								<img className={styles.iconupdate} alt="" src="images/update.svg" />
								<div className={styles.label}>검토 필요</div>
							</div>
							<div className={selectedOption.filter == 'my_pick' ? styles.mypickbuttonSelected : styles.mypickbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'my_pick'}))}>
								<img className={styles.iconstar} alt="" src="images/star_filled.svg" />
								<div className={styles.label}>내 PICK</div>
							</div>
							<div className={selectedOption.filter == 'has_period' ? styles.periodbuttonSelected : styles.periodbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'has_period'}))}>
								<img className={styles.iconupdate} alt="" src="images/notification.svg" />
								<div className={styles.label}>기간</div>
							</div>
							{[...new Set(myHotples.hotples.flatMap(hotple => {
								const taggedByUsernames = hotple.taggedBy ? [hotple.taggedBy] : [];
								const commentUsernames = hotple.comment ? (hotple.comment.match(/(@[\w\.]+)/g) || []).map(username => username.substring(1)) : [];

								return [...taggedByUsernames, ...commentUsernames];
							}).filter(username => username && username !== account.instagramUsername))].map(username => (
								<div
									className={selectedOption.filter == username ? styles.usernamebuttonSelected : styles.usernamebutton}
									onClick={() => setSelectedOption(prev => ({ ...prev, filter: username }))}
									key={username}
								>
									<div className={styles.label4}>@{username}</div>
								</div>
							))}
						</div>
					}
					{selectedHotple==null ?
						mapHotples.hotples.map(hotple=>(
							<HotpleItem key={hotple.hotple_id} hotple={hotple} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple}/>
						))
					:
						<HotpleItem key={selectedHotple.hotple_id} hotple={mapHotples?.hotples?.find(hotple => hotple.hotple_id == selectedHotple?.hotple_id)} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple} isSelected={true}/>
					}
				</div>
			</MobileView>
		</div>
		);
};

export default Contents;
