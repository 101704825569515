import { josa } from '../../../../util';
import styles from './DeleteHotpleModal.module.css';


const DeleteHotpleModal = ({setUserModalPage, modalProps, deleteMyHotple}) => {
  	return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    <b className={styles.title}>
                            <p className={styles.p}>
                                <span>'{modalProps.hotple.name}'</span>
                                <span className={styles.span}> {josa(modalProps.hotple.name,'을를')}</span>
                            </p>
                            <p className={styles.p1}>
                                <span className={styles.span1}>내 핫플 </span>
                                <span>에서 삭제하시겠어요?</span>
                            </p>
                    </b>
                    <div className={styles.buttons}>
                        <div className={styles.cancleButton} onClick={()=>setUserModalPage(0)}>
                                <div className={styles.text}>취소</div>
                        </div>
                        <div className={styles.deleteButton} onClick={()=>deleteMyHotple(modalProps.hotple)}>
                                <div className={styles.text}>삭제하기</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteHotpleModal;