import styles from './BrowserSideTab.module.css';
import Contents from '../Common/Contents/Contents';
import Menu from './Menu/Menu';

const BrowserSideTab = ({ setAuthModalPage, activeMenu, setActiveMenu, account, myHotples, mapHotples, updateHotpleInfo, mapLocation, setIsSearchMode, selectedHotple, setSelectedHotple, selectedOption, setSelectedOption }) => {
	return (
		<div className={styles.sideTab}>
			  <Menu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
			  <Contents activeMenu={activeMenu} setAuthModalPage={setAuthModalPage} account={account} myHotples={myHotples} mapHotples={mapHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
		</div>);
};

export default BrowserSideTab;
