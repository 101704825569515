import styles from './LoginHeader.module.css';


const LoginHeader = ({ setAuthModalPage }) => {
  	return (
		<div className={styles.header}>
			<div className={styles.iconfireParent}>
					<img className={styles.iconfire} alt="" src="images/fire.svg" />
					<img className={styles.hotple1Icon} alt="" src="images/HOTPLE.svg" />
			</div>
			<div className={styles.pick}>핫플 로그인하고, 내가 PICK한 인스타 핫플 확인!</div>
			<div className={styles.buttondefault}  onClick={()=>setAuthModalPage(1)}>
					<img className={styles.iconfire1} alt="" src="images/fire.svg" />
					<div className={styles.text}>HOTPLE 로그인</div>
			</div>
		</div>);
};

export default LoginHeader;
