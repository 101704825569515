import React, { useState, useEffect } from 'react';
import styles from './LoginModal.module.css';
import api from '../../../../api.js';


const LoginModal = ({setAuthModalPage, getMyInfo}) => {
	const [id, setId] = useState('');
	const [password, setPassword] = useState('');
	const [isLoginFailed, setIsLoginFailed] = useState(false);
	const [isAPIFinish, setIsAPIFinish] = useState(true);

	const handleLoginClick = async () => {
		if (id == '' || password == '') return;
		
		if(isAPIFinish){
			setIsAPIFinish(false);

			try {
				const response = await api.post('/auth/login', {
				user_id: id,
				user_pw: password,
				});
				const { accessToken, refreshToken } = response.data;

				localStorage.setItem('accessToken', accessToken);
				localStorage.setItem('refreshToken', refreshToken);
				setIsLoginFailed(false);
				setAuthModalPage(0);
				getMyInfo();
			} catch (error) {
				if (error.response && error.response.status === 400)
					setIsLoginFailed(true);
				else
					console.error('There was an error login!', error);
			} finally {
				setIsAPIFinish(true);
			}
		}
	};

	useEffect(() => {
		setIsLoginFailed(false);
	}, [id,password]);

  	return (
		<div className={styles.loginmodal}>
			<div className={styles.header}>
					<div className={styles.loginframe}>
						<div className={styles.registerframe}>
							<div className={styles.logo}>
								<img className={styles.iconfire} alt="" src="images/fire.svg" />
								<img className={styles.hotple2Icon} alt="" src="images/HOTPLE.svg" />
							</div>
							{isLoginFailed ? <div className={styles.headertext_failed}>ID/PW가 일치하지 않습니다..!</div> : <div className={styles.headertext}>핫플 로그인하고, 내가 PICK 한 장소 확인!</div>}
						</div>
						<div className={styles.loginbox}>
							<div className={styles.logininputs}>
								<div className={styles.idbox}>
									<div className={styles.id}>ID :</div>
									<input
										type="text"
										className={styles.idinput}
										placeholder="아이디"
										value={id}
										onChange={(e) => setId(e.target.value)}
									/>
								</div>
								<div className={styles.idbox}>
									<div className={styles.id}>PW :</div>
									<input
										type="password"
										className={styles.idinput}
										placeholder="비밀번호"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
							</div>
							<div className={styles.loginbutton} onClick={handleLoginClick}>
								<div className={styles.text}>로그인</div>
							</div>
						</div>
					</div>
					<div className={styles.registerframe}>
						<div className={styles.hotple}>아직 HOTPLE 계정이 없으신가요?</div>
						<div className={styles.registerbutton} onClick={()=>setAuthModalPage(2)}>
							<img className={styles.iconcloseFilled} alt="" src="images/fire.svg" />
							<div className={styles.text}>HOTPLE 간편 회원가입</div>
						</div>
					</div>
				</div>
			</div>
	);
};

export default LoginModal;
  