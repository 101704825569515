import { useEffect, useRef, useState } from 'react';
import styles from './ReviewHotpleModal3.module.css';
import { callSearchingPlaces } from '../../../../api';

const { naver } = window;

const ReviewHotpleModal3 = ({setHotpleModalPage, editedHotple, updateHotpleField, originalHotple}) => {
      const mapElement = useRef(null);
      const [isEditMode, setIsEditMode] = useState(false);
      const [map, setMap] = useState(null);
      const [isSearchMode, setIsSearchMode] = useState(false);
      const [query, setQuery] = useState('');
      const [places, setPlaces] = useState([]);
      const [location, setLocation] = useState(null);
      const [timeoutId, setTimeoutId] = useState(null);
      const [marker, setMarker] = useState(null);
      const [newMarker, setNewMarker] = useState(null);

      const whenInputUnfocused =() => {
		setTimeout(() => {
			setIsSearchMode(false);
		}, 100);
	}

      useEffect(() => {
            if (query) {
                  if (timeoutId) {
                  clearTimeout(timeoutId);
                  }

                  const id = setTimeout(() => {
                  fetchPlaces(query);
                  }, 100);

                  setTimeoutId(id);
            } else {
                  if (timeoutId) {
                  clearTimeout(timeoutId);
                  }
                  setPlaces([]);
            }

            return () => clearTimeout(timeoutId);
      }, [query]);

      const fetchPlaces = async (keyword) => {
            const results = await callSearchingPlaces(keyword);
            setPlaces(results);
      };

      const handlePlaceClick = (place) => {
            const { place_name, address_name, y, x, id } = place;
            map.morph(new naver.maps.LatLng(y, x), 16);
            setQuery(place.place_name);
            setLocation({
                  name: place_name,
                  address: address_name,
                  latitude: y,
                  longitude: x,
                  kakao_map_place_id: id,
                  region: address_name.slice(0, 2)
            });
      };

      const handleWrongButtonClick = () => {
            setLocation(null);
            setIsEditMode(true);
      };

      const handleCancelButtonClick = () => {
            if(!originalHotple.need_verify)
                  setHotpleModalPage(11);
            else{
                  setLocation(null);
                  setIsEditMode(false);
            }
      };

      const handleRightButtonClick = () => {
            setHotpleModalPage(4)
      };

      const handleHereButtonClick = async () => {
            if(!location)
                  return;

            updateHotpleField("latitude", location.latitude);
            updateHotpleField("longitude", location.longitude);
            updateHotpleField("place_name", location.name);
            updateHotpleField("address", location.address);
            updateHotpleField("kakao_map_place_id", location.kakao_map_place_id);
            updateHotpleField("region", location.region);

            setLocation(null);
            if(!originalHotple.need_verify)
                  setHotpleModalPage(11);
            else
                  setIsEditMode(false);
      };

      useEffect(() => {
            if (!mapElement.current || !naver) return;
        
            const mapOptions = {
              center: new naver.maps.LatLng(editedHotple.latitude, editedHotple.longitude),
              zoom: 16,
              padding: {top:30},
              logoControlOptions: {
                position: naver.maps.Position.BOTTOM_LEFT,
              },
              disableKineticPan: false,
            };
        
            const mapInstance = new naver.maps.Map(mapElement.current, mapOptions);
            setMap(mapInstance);

            const markerImage = {
                  url: '/images/marker_position.svg', // 마커 이미지 경로
                  size: new naver.maps.Size(52, 58), // 마커 이미지 크기
                  origin: new naver.maps.Point(0, 0), // 이미지 시작 위치
                  anchor: new naver.maps.Point(26, 58), // 마커의 기준점을 이미지 아래쪽 중앙으로 설정
            };
            
            // 마커 생성
            const marker = new naver.maps.Marker({
                  position: new naver.maps.LatLng(editedHotple.latitude, editedHotple.longitude),
                  map: mapInstance,
                  icon: markerImage,
            });

            setMarker(marker);

            document.body.style.overflow = 'hidden';
          }, []);

      useEffect(() => {
            if(marker)
                  marker.setPosition(new naver.maps.LatLng(editedHotple.latitude, editedHotple.longitude));
      },[editedHotple.latitude, editedHotple.longitude]);

      useEffect(() => {
            if(location){
                  if(newMarker)
                        newMarker.setPosition(new naver.maps.LatLng(location.latitude, location.longitude));
                  else{
                        if(isEditMode || !originalHotple.need_verify){
                              const markerImage = {
                                    url: '/images/marker_position_color.svg', // 마커 이미지 경로
                                    size: new naver.maps.Size(52, 58), // 마커 이미지 크기
                                    origin: new naver.maps.Point(0, 0), // 이미지 시작 위치
                                    anchor: new naver.maps.Point(26, 58), // 마커의 기준점을 이미지 아래쪽 중앙으로 설정
                              };
                              const marker = new naver.maps.Marker({
                                    position: new naver.maps.LatLng(location.latitude, location.longitude),
                                    map: map,
                                    icon: markerImage,
                              });
                  
                              setNewMarker(marker);
                        }
                  }
            }
            else{
                  if(newMarker){
                        newMarker.setMap(null);
                        setNewMarker(null);
                  }
            }
      },[location]);

      useEffect(() => {
            setQuery(null);
            if(!isEditMode && map)
                  map.morph(new naver.maps.LatLng(editedHotple.latitude, editedHotple.longitude), 16);
      },[isEditMode]);

      return (
            <div className={styles.reviewmodal3}>
                  <div className={styles.mainframe}>
                        <div className={styles.headerframe}>
                              <b className={styles.b}>
                                    {isEditMode || !originalHotple.need_verify ?
                                          <>
                                                <p className={styles.p_color}>'{editedHotple.name}'<span className={styles.p}> 의</span></p>
                                                <p className={styles.p}>위치를 알려주세요!</p>
                                          </>
                                    :
                                    <>
                                          <p className={styles.p_color}>'{editedHotple.name}'<span className={styles.p}> 의</span></p>
                                          <p className={styles.p}>위치는 정확한가요?</p>
                                    </>
                                    }
                              </b>
                              <a className={styles.instagrambutton} href={originalHotple.media_url} target="blank" rel="noopener noreferrer">
                                    게시글 확인하고 오기 →
                              </a>
                        </div>
                        <div className={styles.contentsframe}>
                              {isEditMode || !originalHotple.need_verify ?
                              <>
                                    <div className={styles.searchframe}/>
                                    <div className={styles.searchheader}>
                                          <div className={isSearchMode&&query!=''? styles.searchframeActive: styles.searchframe}>
                                                <img className={styles.iconsearch} alt="" src="images/search.svg" />
                                                <input
                                                      type="text"
                                                      id="searchinput"
                                                      className={styles.searchinput}
                                                      value={query}
                                                      onChange={(e) => setQuery(e.target.value)}
                                                      onFocus={()=>setIsSearchMode(true)}
                                                      onBlur={() => whenInputUnfocused()}
                                                      placeholder={originalHotple.place_name}
                                                      autoComplete="off"
                                                />
                                          </div>
                                          <div className={styles.bottomframe}>
                                          {places.length > 0 && isSearchMode && (
                                                <>
                                                {places.map((place) => (
                                                      <div className={styles.searchresult} key={place.id} onClick={() => handlePlaceClick(place)}>
                                                            <div className={styles.leftinfo}>
                                                            <div className={styles.placename}>{place.place_name}</div>
                                                            <div className={styles.placeaddress}>{place.road_address_name || place.address_name}</div>
                                                            </div>
                                                            <div className={styles.category}>{place.category_name.split(/[,>]/).pop().trim()}</div>
                                                      </div>
                                                ))}
                                                </>
                                          )}
                                          </div>
                                    </div>
                              </>
                              :
                                    <div className={styles.textframe}>
                                          <div className={styles.nameframe}>
                                                <div className={styles.div}>장소 :</div>
                                                <div className={styles.nametext}>{editedHotple.place_name}</div>
                                          </div>
                                          <div className={styles.nameframe}>
                                                <div className={styles.div}>주소 :</div>
                                                <div className={styles.nametext}>{editedHotple.address}</div>
                                          </div>
                                    </div>
                              }
                              <div ref={mapElement} className={styles.mapframeIcon}/>
                        </div>
                  </div>
                  {isEditMode || !originalHotple.need_verify ?
                        <div className={styles.buttonsframe2}>
                              <div className={styles.cancelButton} onClick={()=>handleCancelButtonClick()}>
                                    <div className={styles.label}>돌아가기</div>
                              </div>
                              <div className={location?styles.hereButton:styles.hereButtonDisabled}
                              disabled={!location}
                              onClick={()=>handleHereButtonClick()}>
                                    <div className={styles.text}>여기예요!</div>
                              </div>
                        </div>
                  :
                        <div className={styles.buttonsframe}>
                              <div className={styles.wrongButton} onClick={()=>handleWrongButtonClick()}>
                                    <div className={styles.text}>틀린 것 같아요..!</div>
                              </div>
                              <div className={styles.rightButton} onClick={()=>handleRightButtonClick()}>
                                    <div className={styles.text}>정확해요 :)</div>
                              </div>
                        </div>
                  }                  
            </div>
      );
};

export default ReviewHotpleModal3;