import { useEffect, useState } from 'react';
import styles from './HotpleModal.module.css';
import ReviewHotpleModal1 from './ReviewHotple/ReviewHotpleModal1';
import ReviewHotpleModal2 from './ReviewHotple/ReviewHotpleModal2';
import ReviewHotpleModal3 from './ReviewHotple/ReviewHotpleModal3';
import ReviewHotpleModal4 from './ReviewHotple/ReviewHotpleModal4';


const HotpleModal = ({hotpleModalPage, setHotpleModalPage, modalProps, getMyHotple}) => {
    const [editedHotple, setEditedHotple] = useState({});

    const goPrevPage = () => {
        setHotpleModalPage(hotpleModalPage-1);
    };

    const goNextPage = () => {
        setHotpleModalPage(hotpleModalPage+1);
    }

    const updateHotpleField = (key, value, tagIndex = null) => {
      setEditedHotple((prevHotple) => {
        if (key === 'tags' && tagIndex !== null) {
          // 기존 tags 배열을 복사하여 수정
          const updatedTags = [...prevHotple.tags];
          updatedTags[tagIndex] = value; // 해당 index의 값만 수정
          return {
            ...prevHotple,
            tags: updatedTags,
          };
        } else {
          // 일반적인 key-value 업데이트
          return {
            ...prevHotple,
            [key]: value,
          };
        }
      });
    };

    useEffect(() => {
        setEditedHotple(modalProps.hotple);
    }, []);

  	return (
        <div className={styles.modalbackground}>
            <div className={styles.modalcontainer}>
                {hotpleModalPage==1 &&<ReviewHotpleModal1 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple}/>}
                {hotpleModalPage==2 &&<ReviewHotpleModal2 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple}/>}
                {hotpleModalPage==3 &&<ReviewHotpleModal3 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple}/>}
                {hotpleModalPage==4 &&<ReviewHotpleModal4 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple} getMyHotple={getMyHotple}/>}

                {hotpleModalPage==11 &&<ReviewHotpleModal4 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple} getMyHotple={getMyHotple}/>}
                {hotpleModalPage==12 &&<ReviewHotpleModal1 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple}/>}
                {hotpleModalPage==13 &&<ReviewHotpleModal2 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple}/>}
                {hotpleModalPage==14 &&<ReviewHotpleModal3 setHotpleModalPage={setHotpleModalPage} editedHotple={editedHotple} updateHotpleField={updateHotpleField} originalHotple={modalProps.hotple}/>}

                {hotpleModalPage > 1 && hotpleModalPage<10 &&
                    <div className={styles.backbutton} onClick={goPrevPage}>
                        <img className={styles.iconcloseLine} alt="" src="images/back.svg" />
                    </div>
                }
                <div className={styles.closebutton} onClick={()=>setHotpleModalPage(0)}>
                    <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                </div>
            </div>
        </div>
    );
};
    
export default HotpleModal;