import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    // 요청이 성공적으로 완료된 경우 응답을 그대로 반환
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true; // 이 플래그를 통해 무한 루프 방지
        try {
          const response = await api.post('/auth/refresh_token', {
            refreshToken,
          });

          const { accessToken, refreshToken: newRefreshToken } = response.data;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', newRefreshToken);

          originalRequest.headers.Authorization = `Bearer ${accessToken}`;

          // 실패한 요청을 새로운 토큰으로 다시 시도
          return api(originalRequest);
        } catch (refreshError) {
          // 리프레시 토큰 만료 또는 리프레시 실패 시 토큰 제거 및 403 처리
          if (refreshError.response && refreshError.response.status === 403) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.reload(); // 403 에러 시 새로고침
          }
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

// 카카오 API를 호출하는 함수 추가
export const callReverseGeoCodingAPI = async (latitude, longitude) => {
  try {
    const response = await axios.get('https://dapi.kakao.com/v2/local/geo/coord2address.json', {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_KEY}`, // .env 파일에 KAKAO API KEY 설정 필요
      },
      params: {
        x: longitude, // 경도
        y: latitude,  // 위도
      }
    });

    if (response.data && response.data.documents && response.data.documents.length > 0) {
      return (response.data.documents[0].address);
    } else {
      console.error('No address found for the provided coordinates.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching address:', error);
    return null;
  }
};

// 카카오 키워드로 장소 검색하기 API 호출 함수
export const callSearchingPlaces = async (keyword) => {
  try {
    const response = await axios.get('https://dapi.kakao.com/v2/local/search/keyword.json', {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_KEY}`, // .env 파일에 KAKAO API KEY 설정 필요
      },
      params: {
        query: keyword,
      },
    });

    return response.data.documents;
  } catch (error) {
    console.error('Error searching places:', error);
    return [];
  }
};

export default api;