import React, { useEffect, useRef } from 'react';
import { BOTTOM_SHEET_HEIGHT } from './MobileBottomSheetOption.js';
import styled from 'styled-components';
import { motion } from "framer-motion";
import useBottomSheet from './useBottomSheet.ts';
import Header from './Header.tsx';
import Contents from '../Common/Contents/Contents.js';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  
  position: fixed;
  z-index: 300;
  top: calc(100% - 86px);
  left: 0;
  right: 0;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: ${BOTTOM_SHEET_HEIGHT}px;
  background: white;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);

  transition: transform 150ms ease-out;
`

const BottomSheetContent = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

function BottomSheet({ activeMenu, setAuthModalPage, account, myHotples, mapHotples, updateHotpleInfo, mapLocation, isSearchMode, setIsSearchMode, selectedHotple, setSelectedHotple, selectedOption, setSelectedOption}) {
  const { sheet, content, setBottomSheetPosition } = useBottomSheet(setSelectedHotple);
  const prevHeight = useRef(370); // 이전 값을 저장할 ref

  useEffect(() => {
		if(isSearchMode)
      setBottomSheetPosition("min");
	}, [isSearchMode])

  useEffect(() => {
    if (selectedHotple) {
      if(content.current){
        const contentsHeight = content.current.offsetHeight;
        setBottomSheetPosition("mid", contentsHeight);
        prevHeight.current=contentsHeight;
      }
    }
    else{
      if(sheet.current && content.current){
        console.log(sheet.current!.getBoundingClientRect().y - (window.innerHeight - prevHeight.current - 70));
        if(sheet.current!.getBoundingClientRect().y < window.innerHeight - prevHeight.current - 70)
          setBottomSheetPosition("max");
        else
          setBottomSheetPosition("min");

        prevHeight.current=0;
      }
    }
	}, [selectedHotple])

  return (
    <Wrapper ref={sheet}>
      <Header />
      <BottomSheetContent ref={content}>
        <Contents activeMenu={activeMenu} setAuthModalPage={setAuthModalPage} account={account} myHotples={myHotples} mapHotples={mapHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
      </BottomSheetContent>
    </Wrapper>
  );
}

export default BottomSheet;
