import React, { useState, useEffect } from 'react';
import styles from './RegisterModal.module.css';
import api from '../../../../api.js';

const RegisterModal = ({setAuthModalPage, getMyInfo}) => {
	const [id, setId] = useState('');
	const [password, setPassword] = useState('');
	const [isIdLengthValid, setIsIdLengthValid] = useState(false);
	const [isIdCharValid, setIsIdCharValid] = useState(false);
	const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
	const [isPasswordCharValid, setIsPasswordCharValid] = useState(false);
	const [isDuplicatedID, setIsDuplicatedID] = useState(false);

	const handleRegisterClick = async () => {
		if (!isFormValid || isDuplicatedID) return;

		try {
			const response = await api.post('/user/register', {
			  user_id: id,
			  user_pw: password,
			});
			proceedLogin();
		} catch (error) {
			if (error.response && error.response.status === 409)
				setIsDuplicatedID(true);
			else
				console.error('There was an error registering the user!', error);
		}
	};

	const proceedLogin = async () => {
		if (id == '' || password == '') return;

		try {
			const response = await api.post('/auth/login', {
			  user_id: id,
			  user_pw: password,
			});
			const { accessToken, refreshToken } = response.data;

			localStorage.setItem('accessToken', accessToken);
			localStorage.setItem('refreshToken', refreshToken);
			setAuthModalPage(0);
			getMyInfo();
		} catch (error) {
			console.error('There was an error login!', error);
		}
	};

	// Validate ID length and character
	useEffect(() => {
		setIsDuplicatedID(false);
		setIsIdLengthValid(id.length >= 5 && id.length <= 20);
		setIsIdCharValid(/^(?=.*[a-zA-Z])(?=.*\d)(?!.*[ㄱ-ㅎㅏ-ㅣ가-힣])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]+$/.test(id));
	}, [id]);

	// Validate Password length and character
	useEffect(() => {
		setIsPasswordLengthValid(password.length >= 8 && password.length <= 20);
		setIsPasswordCharValid(/^(?=.*[a-zA-Z])(?=.*\d)(?!.*[ㄱ-ㅎㅏ-ㅣ가-힣])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]+$/.test(password));
	}, [password]);

	const isFormValid = isIdLengthValid && isIdCharValid && isPasswordLengthValid && isPasswordCharValid;

	return (
		<div className={styles.headerParent}>
			<div className={styles.header}>
				<div className={styles.idpwParent}>

					{isDuplicatedID ? <b className={styles.idpw_duplicated}>이미 사용 중인 아이디에요..!</b> : <b className={styles.idpw}>ID/PW만 설정하면 끝!</b>}
					<div className={styles.frameParent}>
						<div className={styles.idParent}>
							<div className={styles.id}>ID :</div>
							<input
								type="text"
								className={styles.idinput}
								placeholder="아이디"
								value={id}
								onChange={(e) => setId(e.target.value)}
							/>
							<div className={styles.buttondefault}>
								<div className={styles.text}>
									<ul className={styles.ul}>
										<li className={isIdLengthValid  ? styles.li : styles.li_invalid}>
											5~20자
										</li>
										<li className={isIdCharValid  ? styles.li : styles.li_invalid}>
											영문/숫자
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={styles.pwParent}>
							<div className={styles.id}>PW :</div>
							<input
								type="password"
								className={styles.idinput}
								placeholder="비밀번호"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<div className={styles.buttondefault}>
								<div className={styles.text}>
									<ul className={styles.ul}>
										<li className={isPasswordLengthValid  ? styles.li : styles.li_invalid}>
											8~20자
										</li>
										<li className={isPasswordCharValid  ? styles.li : styles.li_invalid}>
											영문/숫자
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.buttondefaultWrapper}>
					<div
						className={`${styles.buttondefault1} ${isFormValid && !isDuplicatedID ? '' : styles.buttondefault1_invalid}`}
						disabled={!isFormValid}
						onClick={handleRegisterClick}
					>
						<div className={`${styles.text1} ${isFormValid && !isDuplicatedID ? '' : styles.text1_invalid}`}>회원가입 완료 :)</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterModal;
