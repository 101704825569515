import React, { useEffect, useRef, useState } from 'react';
import './NaverMap.css';
import MapHeader from './MapHeader/MapHeader';
import MarkerClustering from './MarkerClustering';
import api, {callReverseGeoCodingAPI} from '../../api';
import SearchHeader from './SearchHeader/SearchHeader';
import { isMobile } from 'react-device-detect';
import { share, shareContent } from '../../util';


const { naver } = window;

const NaverMap = ({ activeMenu, selectedOption, setSelectedOption, mapHotples, setNearbyHotples, mapLocation, setMapLocation, updateHotpleInfo, isSearchMode, setIsSearchMode, selectedHotple, setSelectedHotple }) => {
  const mapElement = useRef(null);
  const [isMapLoad, setIsMapLoad] = useState(false);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [infoWindow, setInfoWindow] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [rangeCircle, setRangeCircle] = useState(null);
  const [positionMarker, setPositionMarker] = useState(null);
  const [searchButtonFlag, setSearchButtonFlag] = useState(false);

  useEffect(() => {
    if (!mapElement.current || !naver) return;

    const mapOptions = {
      center: new naver.maps.LatLng(37.5695704, 126.978399),
      zoom: 13,
      logoControlOptions: {
        position: naver.maps.Position.BOTTOM_LEFT,
      },
      disableKineticPan: false,
    };

    const mapInstance = new naver.maps.Map(mapElement.current, mapOptions);
    setMap(mapInstance);

    // 현재 위치 가져오기
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = new naver.maps.LatLng(latitude, longitude);
          mapInstance.setCenter(currentLocation);
          setIsMapLoad(true);
        },
        (error) => {
          console.error('Error fetching geolocation:', error);
          setIsMapLoad(true);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setIsMapLoad(true);
    }
    document.body.style.overflow = 'hidden';
  }, []);

  function haversineDistance(coord1, coord2) {
    const R = 6371; // 지구 반경(km)
    const lat1 = coord1.lat();
    const lon1 = coord1.lng();
    const lat2 = coord2.lat();
    const lon2 = coord2.lng();
  
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
  
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c; // 두 좌표 간의 거리(km)
    return distance;
  }

  function getMapRange() {
    const bounds = map.getBounds();

    const southWest = bounds.getSW(); // 남서쪽 좌표 (Latitude, Longitude)
    const northEast = bounds.getNE(); // 북동쪽 좌표 (Latitude, Longitude)
    
    // 좌우 거리 (동서 거리)
    const horizontalDistance = haversineDistance(
      new naver.maps.LatLng(southWest.lat(), southWest.lng()),
      new naver.maps.LatLng(southWest.lat(), northEast.lng())
    );
    
    // 위아래 거리 (남북 거리)
    const verticalDistance = haversineDistance(
      new naver.maps.LatLng(southWest.lat(), southWest.lng()),
      new naver.maps.LatLng(northEast.lat(), southWest.lng())
    );
    
    // 더 큰 거리 반환
    return Math.max(horizontalDistance, verticalDistance);
  }

  const clearMarkers = () => {
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);
  };

  const whenSearchButtonClicked = () => {
    setSearchButtonFlag(false);
    getNearByHotple();
  };

  const showHotpleDetail = (hotple) => {
    const { latitude, longitude } = hotple;
    const position = new naver.maps.LatLng(latitude, longitude);

    if (infoWindow) {
      infoWindow.close();
    }

    const newInfoWindow = new naver.maps.InfoWindow({
        content: `
          <div class="infoframe">
            <a class="instagrambutton" href="${hotple.media_url}" target="_blank" rel="noopener noreferrer">
              <img class="iconinstagram" alt="" src="images/instagram.svg" />
              <div class="instagramtext">게시글 이동</div>
            </a>
            <div class="divider"></div>
            <a class="routebutton">
              <img class="iconcourse" alt="" src="images/course.svg" />
              <div class="instagramtext">경로 안내</div>
            </a>
            <div class="divider"></div>
            <a class="sharebutton">
              <img class="iconshare" alt="" src="images/share.svg" />
              <div class="instagramtext">공유</div>
            </a>
          </div>
        `,  // 렌더링된 React 컴포넌트가 포함된 div를 설정
        backgroundColor: "transparent",
        borderColor: "transparent",
        pixelOffset : new naver.maps.Point(0, -45),
        borderWidth: 0,
        disableAnchor: true,
        anchorColor: "#fff",
    });

    naver.maps.Event.addListener(newInfoWindow, 'open', () => {
      setTimeout(()=>{
        const routeButton = document.querySelector('.routebutton');
        const shareButton = document.querySelector('.sharebutton');

        routeButton.addEventListener('click', () => {    
          if (isMobile) {
            window.open(`nmap://route/car?dlat=${hotple.latitude}&dlng=${hotple.longitude}&dname=${hotple.name}&appname=hotple`);
            setTimeout(() => {
              window.open(`https://map.naver.com/v5/directions/-/${hotple.longitude},${hotple.latitude},${hotple.name}/-/car`, '_blank');
            }, 500);
          } else
            window.open(`https://map.naver.com/v5/directions/-/${hotple.longitude},${hotple.latitude},${hotple.name}/-/car`, '_blank');
        });

        shareButton.addEventListener('click', async () => {
          await shareContent('인스타 핫플! 여긴 어때요?',`${hotple.name}`,`https://hotple.today?menu=nearby&hotple_id=${hotple.hotple_id}`);
        });
      });
    });

    newInfoWindow.open(map, position);
    setInfoWindow(newInfoWindow);
  };

  const whenHotpleSelected = () => {
    showHotpleDetail(selectedHotple);
    setIsSearchMode(false);

    const markerPosition = new naver.maps.LatLng(selectedHotple.latitude, selectedHotple.longitude);
    const projection = map.getProjection();
    
    const point = projection.fromCoordToOffset(markerPosition);
    
    const newPoint = new naver.maps.Point(point.x, point.y + 90);
  
    const newPosition = projection.fromOffsetToCoord(newPoint);
    map.panTo(newPosition,{duration:500});
  };

  const createCustomMarker = (hotple) => {
    const { latitude, longitude, name, main_category, sub_category1 } = hotple;

    const position = new naver.maps.LatLng(latitude, longitude);
    let marker_file="";

    switch(main_category) {
      case "음식점":
        marker_file="restaurant";
        break;
      case "카페&디저트":
        marker_file="cafe";
        break;
      case "주점":
        marker_file="alcohol";
        break;
      case "팝업스토어":
        marker_file="popupStore";
        break;
      case "문화예술":
        marker_file="cultural";
        break;
      case "오락액티비티":
        marker_file="activity";
        break;
      case "자연힐링":
        marker_file="nature";
        break;
      default:
        marker_file="cultural";
    }

    const content = `
      <div id='temp-marker' class='parent_marker'>
        <div class='div_marker'>
          <p class='p_marker'>${name}</p>
          <p class='p1_marker'>${sub_category1}</p>
        </div>
        <img class='markercourseAddIcon' alt="" src="images/marker_${marker_file}.png" />
      </div>
    `;

    // 1. content를 임시로 DOM에 추가하여 width 측정
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    document.body.appendChild(tempDiv);

    const tempMarker = tempDiv.querySelector('#temp-marker');
    const contentWidth = tempMarker.offsetWidth;

    // 2. content의 width에 따라 anchor 좌표 계산
    const anchorX = contentWidth - 19;

    // 3. 임시 DOM 요소 제거
    document.body.removeChild(tempDiv);

    // 4. naver.maps.Marker 생성
    const marker = new naver.maps.Marker({
      position,
      map,
      icon: {
        content,
        anchor: new naver.maps.Point(anchorX, 43), // 동적으로 계산한 anchorX 값
      },
    });

    naver.maps.Event.addListener(marker, 'click', () => {
      setSelectedHotple(hotple);
    });

    return marker;
  };

  const getNearByHotple = async (lat=null, long=null, range=null) => {
    try {
      setSelectedOption(
        {...selectedOption,
          region: '위치'
        });
      if(isMapLoad){
        const params = new URLSearchParams(window.location.search);
        const hotpleId = params.get('hotple_id');
        const menu = params.get('menu');
        let response;
        let latitude, longitude;

        if(menu == 'nearby' && hotpleId){ //공유 url을 타고 들어온 것이라면
          response = await api.get(`/hotple/${hotpleId}`);
          if(response.data.hotples.length > 0){
            latitude = response.data.hotples[0].latitude;
            longitude = response.data.hotples[0].longitude;

            setSelectedHotple(response.data.hotples[0]);
          }
          else{
            const center = map.getCenter();
            latitude = lat || center.lat();
            longitude = long || center.lng();
            const searchRange = range || getMapRange()/2*1.2;

            response = await api.get(`/hotple/nearby`,{
              params: {
                category: selectedOption.category,
                range: searchRange,
                long: longitude,
                lat: latitude
              }
            });

            drawRangeCircle(latitude, longitude, searchRange);
            drawPositionMarker(latitude, longitude);
          }

          const newUrl = window.location.pathname;
          window.history.replaceState({}, '', newUrl);
        }
        else{
          const center = map.getCenter();
          latitude = lat || center.lat();
          longitude = long || center.lng();
          const searchRange = range || getMapRange()/2*1.2;

          response = await api.get(`/hotple/nearby`,{
            params: {
              category: selectedOption.category,
              range: searchRange,
              long: longitude,
              lat: latitude
            }
          });

          drawRangeCircle(latitude, longitude, searchRange);
          drawPositionMarker(latitude, longitude);
        }

        setNearbyHotples(response.data);
        const result = await callReverseGeoCodingAPI(latitude, longitude);

        if(result){
          setMapLocation(
            {...mapLocation,
              address:`${result.region_1depth_name} ${result.region_2depth_name} ${result.region_3depth_name.split(' ')[0]}`,
              latitude: latitude,
              longitude: longitude,
              name: (lat ? mapLocation.name : null )
            });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getRegionHotple = async () => {
    try {
      if(isMapLoad){
        const response = await api.get(`/hotple/region`,{
          params: {
            category: selectedOption.category,
            region: selectedOption.region,
          }
        });

        deletePositionMarker();
        deleteRangeCircle();
        setNearbyHotples(response.data);

        moveMapToShowAllMarkers(response.data.hotples);

        setMapLocation(
          {...mapLocation,
            name: selectedOption.region
          });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const moveMapToShowAllMarkers = (hotples) => {
    const bounds = new naver.maps.LatLngBounds();
    hotples.forEach(hotple => bounds.extend(new naver.maps.LatLng(hotple.latitude, hotple.longitude)));

    // 패딩을 주고 경계에 맞춰 카메라 이동
    map.fitBounds(bounds, {
      top: 40, // 패딩 (위쪽)
      right: 40, // 패딩 (오른쪽)
      bottom: 40, // 패딩 (아래쪽)
      left: 40 // 패딩 (왼쪽)
    });
  };

  const drawRangeCircle = (lat, long, radius) => {
    deleteRangeCircle();

    setRangeCircle(new naver.maps.Circle({
      map: map,
      center: new naver.maps.LatLng(lat, long),
      radius: radius*1000,
      strokeColor: '#ff0069',
      strokeWeight: 2,
    }));
  }

  const deleteRangeCircle = () => {
    if(rangeCircle)
      rangeCircle.setMap(null);
  }

  const deletePositionMarker = () => {
    if (positionMarker) {
      positionMarker.setMap(null); // 기존 마커가 존재하면 지도에서 제거
      setPositionMarker(null); // 상태 초기화
    }
  };

  const drawPositionMarker = (lat, long) => {
    deletePositionMarker(); // 기존 마커 삭제

    // 새로운 원형 마커 생성
    const marker = new naver.maps.Marker({
      position: new naver.maps.LatLng(lat, long),
      map: map, // 지도를 가리키는 변수 (props나 state로 전달된 지도를 사용)
      icon: {
        content: `
          <div style="
            width: 10px;
            height: 10px;
            background-color: #ff0069;
            border-radius: 50%;
            border: 2px solid white;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          "></div>
        `,
        anchor: new naver.maps.Point(7, 7) // 마커의 앵커를 중앙으로 설정
      }
    });

    setPositionMarker(marker); // 새로운 마커를 상태에 저장
  };

  const showMapHotples = (hotples) => {
    if (!map) return;

    clearMarkers();
    const newMarkers = hotples.map(hotple => createCustomMarker(hotple));

    setMarkers(newMarkers);

    // 클러스터링 적용
    if (cluster) {
      cluster.setMap(null); // 기존 클러스터 삭제
    }

    const newCluster = new MarkerClustering({
      map: map,
      markers: newMarkers,
      disableClickZoom: false, // 클러스터 클릭 시 줌 인 가능
      gridSize: 100,
      minClusterSize: 2,
      maxZoom: 14,
      icons: [
        {
          content: `<div class="cluster cluster-1"></div>`,
          size: new naver.maps.Size(40, 40),
          anchor: new naver.maps.Point(20, 20)
        },
        {
          content: `<div class="cluster cluster-2"></div>`,
          size: new naver.maps.Size(50, 50),
          anchor: new naver.maps.Point(25, 25)
        },
        {
          content: `<div class="cluster cluster-3"></div>`,
          size: new naver.maps.Size(60, 60),
          anchor: new naver.maps.Point(30, 30)
        }
      ],
      indexGenerator: [5, 10, 20],
      stylingFunction: (clusterMarker, count) => {
        const countDiv = clusterMarker.getElement().querySelector('div');
        if (countDiv) {
          countDiv.textContent = count;
        }
      }
    });

    setCluster(newCluster);
  };

  useEffect(() => {
    if(activeMenu=='nearby')
      if(mapLocation.name == selectedOption.region) //지역 검색 중이라면...
        getRegionHotple();
      else
        getNearByHotple();
    else if(activeMenu=='my')
      showMapHotples(mapHotples.hotples)
  }, [selectedOption.category]);

  useEffect(() => {
    if(selectedOption.region!='위치'){
      if(activeMenu=='nearby')
        getRegionHotple();
      else if(activeMenu=='my'){
        showMapHotples(mapHotples.hotples)
        setMapLocation(
          {...mapLocation,
            name: selectedOption.region
          });
      }
    }
  }, [selectedOption.region]);

  useEffect(() => {
    if (infoWindow)
      infoWindow.close();
    if(activeMenu=='nearby')
      getNearByHotple();
    else{
      deleteRangeCircle();
      deletePositionMarker();
    }
  }, [activeMenu]);

  useEffect(() => {
    showMapHotples(mapHotples.hotples);
  }, [mapHotples]);

  useEffect(() => {
    if (isMapLoad && map) {
      getNearByHotple();
    }
  }, [isMapLoad]);

  useEffect(() => {
    if (isMapLoad && map) {
      naver.maps.Event.addListener(map, 'click', () => {
          setIsSearchMode(false);
          setSelectedHotple(null);
          if (infoWindow)
              infoWindow.close();
      });

      naver.maps.Event.addListener(map, 'drag', () => {
          setIsSearchMode(false);
          setSearchButtonFlag(true);
          setSelectedHotple(null);
          if (infoWindow)
              infoWindow.close();
      });

      naver.maps.Event.addListener(map, 'zoom_changed', () => {
        setSearchButtonFlag(true);
        setSelectedHotple(null);
        if (infoWindow)
            infoWindow.close();
      });
    }
  }, [isMapLoad, infoWindow]);

  useEffect(() => {
    if (isMapLoad && map) {
      if(mapLocation.name!=null){
        if(mapLocation.name != selectedOption.region){  //지역 버튼 선택으로 name이 바뀐 것이 아니라면...
          setIsSearchMode(false);
          const { latitude, longitude } = mapLocation;
          deletePositionMarker();
          map.setZoom(13, false);
          map.panTo(new naver.maps.LatLng(latitude, longitude));
          if(activeMenu=="nearby")
            getNearByHotple(latitude,longitude,20)
          else
            drawPositionMarker(latitude, longitude);
        }
        else
          deletePositionMarker();
      }
    }
  }, [mapLocation.name]);

  useEffect(() => {
		if(isSearchMode){
      setSelectedHotple(null);
      if (infoWindow)
        infoWindow.close();
		}
	}, [isSearchMode])

  useEffect(() => {
		if(selectedHotple!=null)
      whenHotpleSelected()
    else{
      if (infoWindow)
        infoWindow.close();
    }
	}, [selectedHotple])

  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Escape')
          setIsSearchMode(false);
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div ref={mapElement} className="map">
      <MapHeader activeMenu={activeMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} mapHotples={mapHotples} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode}/>
      {isSearchMode && <SearchHeader selectedOption={selectedOption} setSelectedOption={setSelectedOption} setMapLocation={setMapLocation} setIsSearchMode={setIsSearchMode}/>}
      {activeMenu=='nearby' && searchButtonFlag &&
        <div className="buttonMap_searchBT" onClick={()=>whenSearchButtonClicked()}>
          <img className="iconrefresh_searchBT" alt="" src="images/refresh.svg" />
          <div className="label_searchBT">현 지도에서 탐색</div>
        </div>
      }
    </div>
  );
};

export default NaverMap;
