import { isMobile } from 'react-device-detect';
import styles from './HotpleItem.module.css';


const HotpleItem = ({hotple, activeMenu, updateHotpleInfo, setSelectedHotple, isSelected}) => {
	const patchIsPick = () => {
		const updatedHotple = {
			...hotple,
			is_pick: !hotple.is_pick,
			mention_count: hotple.mention_count + (!hotple.is_pick ? 1 : -1)
		};

		updateHotpleInfo('updateNearbyHotple', updatedHotple);
	};

	const whenHotpleClicked = () =>{
		
		if(!isSelected){
			setSelectedHotple(null);
			setTimeout(() => {
				setSelectedHotple(hotple);
			}, 50);
		}
	};

	const whenRightButtonClicked = () => {
		if(hotple.need_verify)
			updateHotpleInfo('reviewHotple', hotple);
		else
			updateHotpleInfo('editHotple', hotple);
	};

  	return (
		<div className={activeMenu=='my' ? styles.myHotpleItem : styles.nearbyHotpleItem} onClick={whenHotpleClicked} data-status={isMobile? false : isSelected}>
			{activeMenu=='my' &&
				<div className={styles.iconcloseLineParent}>
					<img className={styles.iconcloseLine} onClick={(event)=> {event.stopPropagation(); updateHotpleInfo('deleteMyHotple',hotple);}} alt="" src="images/close_line.svg" />
				</div>
			}

			<div className={styles.imageframe}>
				{hotple.contents_url.length > 0 &&
					<div className={styles.image1frame} onClick={whenHotpleClicked}>
						<img className={styles.image1Icon} alt="" src={hotple.thumbnail_url} />
						<div className={styles.infoframe}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
					</div>
				}

				{hotple.contents_url.length > 1 &&
					<div className={styles.image2frame} onClick={whenHotpleClicked}>
						<img className={styles.image1Icon} alt="" src={hotple.contents_url[1]} />
						<div className={styles.infoframe}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
					</div>
				}

				{hotple.contents_url.length>2 ?
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<img className={styles.image1Icon} alt="" src={hotple.contents_url[2]} />
						<div className={styles.infoframe2}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
						<div className={styles.infos}>
							<b className={styles.imagenum}>+{hotple.contents_url.length-2}장</b>
							<div className={styles.div}>
								<span className={styles.txt}>
										<p className={styles.p}>게시글</p>
										<p className={styles.p}>보러가기</p>
								</span>
							</div>
						</div>
					</a>
					:
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<img className={styles.image1Icon} alt="" src={hotple.contents_url[0].includes("mp4") ? hotple.thumbnail_url : hotple.contents_url[0]} />
						<div className={styles.infoframe2}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
						<div className={styles.infos}>
							<img className={styles.iconshare} alt="" src="images/share.svg" />
							<div className={styles.div}>
								<span className={styles.txt}>
										<p className={styles.p}>게시글</p>
										<p className={styles.p}>보러가기</p>
								</span>
							</div>
						</div>
					</a>
				}

				{hotple.contents_url.length <= 1 &&
					<div className={styles.image2frame} onClick={whenHotpleClicked}/>
				}
			</div>
			<div className={styles.detailframe}>
					<div className={styles.detailleft}>
						<div className={styles.title}>
								<div className={styles.hotplename}>{hotple.name}</div>
								<div className={styles.ratingframe}>
									<img className={styles.iconfire} alt="" src="images/fire.svg" />
									<div className={styles.ratingtext}>{hotple.mention_count}명이 PICK 했어요!</div>
								</div>
						</div>
						<div className={styles.tags}>
								<div className={styles.tagplaceDetail}>
									<div className={styles.orange}>{hotple.main_category}</div>
								</div>
								<div className={styles.tagplaceDetail1}>
									<div className={styles.orange}>{hotple.sub_category1}</div>
								</div>
								{hotple.tags.map((tag, index) => (
									<div className={styles.tagplaceDetail2} key={index + 1}>
										<div className={styles.orange}>#{tag}</div>
									</div>
								))}
						</div>
						<div className={styles.caption}>{hotple.caption}</div>
						{hotple.comment &&
							<div className={styles.commentframe}>	
									<div className={styles.label}>댓글</div>
									<div className={styles.comment}>
										{hotple.taggedBy!=null &&
											<a className={styles.taggedBy} href={`https://instagram.com/${hotple.taggedBy}`} target="_blank" rel="noopener noreferrer">@{hotple.taggedBy}: </a>
										}
										{hotple.comment.split(/(@[\w\.]+)/g).map((part, index) => {
											if (part.startsWith('@')) {
											// 유저 태그 부분 처리
											const username = part.slice(1); // '@' 제거한 유저 이름
											return (
												<a
												key={index}
												className={styles.commentUser}
												href={`https://instagram.com/${username}`}
												target="_blank"
												rel="noopener noreferrer"
												>
												{part}
												</a>
											);
											} else {
											// 일반 텍스트 처리
											return (
												<span key={index} className={styles.span}>
												{part}
												</span>
											);
											}
										})}
									</div>
							</div>
						}
						{hotple.has_period &&
							<div className={styles.commentframe}>
									<div className={styles.label}>기간</div>
									<div className={styles.period}>{hotple.start_date && hotple.start_date.replaceAll('-','/')} ~ {hotple.end_date && hotple.end_date.replaceAll('-','/')}</div>
							</div>
						}
					</div>
					{activeMenu=='nearby' ?
						<div className={styles.detailrightbutton} onClick={(event) => {event.stopPropagation(); patchIsPick();}}>
							{hotple.is_pick ?
								<img className={styles.iconlikeActive} alt="" src= "images/like_active.svg" />
								:
								<img className={styles.iconlikeOutlined} alt="" src= "images/like_outlined.svg" />
							}
							<div className={hotple.is_pick ? styles.is_pick : styles.not_pick}>PICK</div>
						</div>
					:
						<div className={styles.detailrightbutton} onClick={(event)=>{event.stopPropagation();whenRightButtonClicked();}}>
							{hotple.need_verify ?
								<img className={styles.iconlikeActive} alt="" src= "images/update.svg" />
								:
								<img className={styles.iconlikeOutlined} alt="" src= "images/pen.svg" />
							}
							<div className={hotple.need_verify ? styles.is_pick : styles.not_pick}> {hotple.need_verify ? "검토 필요" : "정보 수정"} </div>
						</div>
					}
			</div>
		</div>
	);
};

export default HotpleItem;
