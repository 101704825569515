import React, { useState, useEffect } from 'react';
import api from './api.js';
import AuthModal from './Compoenent/Modal/Auth/AuthModal.js';
import styles from './MainFrame.module.css';
import NaverMap from './Compoenent/Map/NaverMap';
import BrowserSideTab from './Compoenent/SideTab/BrowserSideTab/BrowserSideTab.js';
import BottomSheet from './Compoenent/SideTab/MobileBottomSheet/MobileBottomSheet.tsx';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import NavigationBar from './Compoenent/SideTab/MobileBottomSheet/NavigationBar.js';
import UserModal from './Compoenent/Modal/User/UserModal.js';
import HotpleModal from './Compoenent/Modal/Hotple/HotpleModal.js';

const MainFrame = () => {
  const [authModalPage, setAuthModalPage] = useState(0);
  const [userModalPage, setUserModalPage] = useState(0);
  const [hotpleModalPage, setHotpleModalPage] = useState(0);
  const [mapLocation, setMapLocation] = useState({name: null, address: '', latitude: null, longitude: null});
  const [modalProps, setModalProps] = useState({});
  const [selectedOption, setSelectedOption] = useState({region:'전국', category: 'recommend', filter: 'all'})
  const [activeMenu, setActiveMenu] = useState('nearby');
  const [account, setAccount] = useState({hotpleId:null, instagramUsername:null});
  const [myHotples, setMyHotples] = useState({hotples:[]});
  const [nearbyHotples, setNearbyHotples] = useState({hotples:[]});
  const [mapHotples, setMapHotples] = useState({hotples:[]});
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [selectedHotple, setSelectedHotple] = useState(null);

  const handleAutoLogin = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const params = new URLSearchParams(window.location.search);
    const hotpleId = params.get('hotple_id');
    const menu = params.get('menu');

    if (refreshToken) {
      try {
        const response = await api.post('/auth/refresh_token', {
          refreshToken,
        });

        const { accessToken, refreshToken: newRefreshToken } = response.data;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        getMyInfo();
      } catch (error) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        
        if(menu && hotpleId)
          setAuthModalPage(1);
      }
    }
    else{
      if(menu && hotpleId)
        setAuthModalPage(1);
    }
  };

  const getMyInfo = async () => {
    try {
      const response = await api.get(`/user/my/`);

      setAccount({hotpleId:response.data.user_id, instagramUsername:response.data.instagram_username});

      getMyHotple()

      if(response.data.instagram_username==null)
        setAuthModalPage(3);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getMyHotple = async () => {
    try {
      const response = await api.get(`/hotple/my/`);

      setMyHotples(response.data);

      const params = new URLSearchParams(window.location.search);
      const hotpleId = params.get('hotple_id');
      const menu = params.get('menu');

      if(menu == 'my' && hotpleId){
        setActiveMenu(menu);
        setTimeout(() => {
          const foundHotple = response.data.hotples.find(hotple => hotple.hotple_id == hotpleId);
          if(foundHotple){
            setSelectedHotple(foundHotple);

            const newUrl = window.location.pathname;
            window.history.replaceState({}, '', newUrl);
          }
        }, 200);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateHotpleInfo = (action, hotple) => {
    switch(action){
      case 'updateNearbyHotple':
        if(account.hotpleId!=null){
          updateNearbyHotple(hotple);
          if(hotple.is_pick)
            addMyHotpleIfNotExist(hotple);
          else
            deleteMyHotple(hotple);
        }
        else{
          setAuthModalPage(1);
        }
        break;
      case 'updateMyHotple':
        updateMyHotple(hotple);
        break;
      case 'deleteMyHotple':
        setModalProps({hotple:hotple});
        setUserModalPage(1);
        break;
      case 'reviewHotple':
        setModalProps({hotple:hotple});
        setHotpleModalPage(1);
        break;
      case 'editHotple':
        setModalProps({hotple:hotple});
        setHotpleModalPage(11);
        break;
    }
  };

  const deleteMyHotple = async (deleteHotple) => {
    if(myHotples.hotples.some(hotple => hotple.hotple_id == deleteHotple.hotple_id)){
      setMyHotples({hotples: myHotples.hotples.filter(hotple => hotple.hotple_id !== deleteHotple.hotple_id)});
      setUserModalPage(0);
      if(activeMenu=='my')  //my탭에서 hotple delete 시, info창 없애기 위해
        setSelectedHotple(null);

      try {
        const response = await api.patch(`/user/pick/`,{
          is_pick: false,
          hotple_id: deleteHotple.hotple_id
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  const updateMyHotple = (newHotple) => {
    setMyHotples({hotples: myHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const updateNearbyHotple = async (newHotple) => {
    setNearbyHotples({hotples: nearbyHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const addMyHotpleIfNotExist = async (newHotple) => {
    if(!myHotples.hotples.some(hotple => hotple.hotple_id == newHotple.hotple_id)){
      newHotple.need_verify = false;
      newHotple.from_instagram = false;
      setMyHotples({hotples: [...myHotples.hotples, newHotple]});
    }

    try {
			const response = await api.patch(`/user/pick/`,{
				is_pick: true,
				hotple_id: newHotple.hotple_id
			});
		} catch (error) {
			console.error('Error fetching data:', error);
		}
  }

  const setFilteredMapHotples = (hotples) => {
    let category_kor;
    switch(selectedOption.category) {
      case "cafe":
          category_kor='카페&디저트'
          break;
      case "alcohol":
          category_kor='주점'
          break;
      case "restaurant":
          category_kor='음식점'
          break;
      case "popupStore":
          category_kor='팝업스토어'
          break;
      case "cultural":
          category_kor='문화예술'
          break;
      case "activity":
          category_kor='오락액티비티'
          break;
      case "nature":
          category_kor='자연힐링'
          break;
      default:
          category_kor='추천'
    }

    let filteredHotples = hotples;

    if(selectedOption.region!='전국' && selectedOption.region!='위치'){
      filteredHotples = {hotples: filteredHotples.hotples.filter(hotple => hotple.region == selectedOption.region)}
    }

    if(selectedOption.category!='recommend')
      filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.main_category == category_kor)}

    if(activeMenu == 'my'){
      switch(selectedOption.filter){
        case null:
        case 'all':
          break;
        case 'need_verify':
          filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.need_verify == true)}
          break;
        case 'my_pick':
          filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.taggedBy == null)}
          break;
        case 'has_period':
          filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.has_period == true)}
          break;
        default:  //username인 경우
          filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => (hotple.taggedBy == selectedOption.filter || hotple.comment.includes(selectedOption.filter)))}
      }
    }

    setMapHotples(filteredHotples);
  };

  useEffect(() => {
    handleAutoLogin();
  }, []);

  useEffect(() => {
    setSelectedHotple(null);
    if(activeMenu=='my'){
      if(account.hotpleId!=null)
        setFilteredMapHotples(myHotples);
      else{
        setAuthModalPage(1);
        setMapHotples({hotples: []})
      }
    }
  }, [activeMenu]);

  useEffect(() => {
      if(activeMenu=='nearby')
        setFilteredMapHotples(nearbyHotples);
  }, [nearbyHotples]);

  useEffect(()=>{
    if(activeMenu=='my' && account.hotpleId!=null)
      setFilteredMapHotples(myHotples);
  }, [myHotples])

  useEffect(() => {
    if(account.hotpleId==null){
      setMyHotples({hotples:[]});
      if(activeMenu=='my')
        setActiveMenu('nearby');
    }
  },[account])

  useEffect(() => {
    if(activeMenu=='my' && account.hotpleId!=null)
      setFilteredMapHotples(myHotples);
  },[selectedOption])

  return (
    <div className={styles.mainFrameContainer}>
      <BrowserView className={styles.browserContainer}>
          <BrowserSideTab setAuthModalPage={setAuthModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} account={account} myHotples={myHotples} mapHotples={mapHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
          <NaverMap activeMenu={activeMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} mapHotples={mapHotples} setNearbyHotples={setNearbyHotples} mapLocation={mapLocation} setMapLocation={setMapLocation} updateHotpleInfo={updateHotpleInfo} isSearchMode={isSearchMode} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple}/>
      </BrowserView>

      <MobileView className={styles.mobileContainer}>
          <NaverMap activeMenu={activeMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} mapHotples={mapHotples} setNearbyHotples={setNearbyHotples} mapLocation={mapLocation} setMapLocation={setMapLocation} updateHotpleInfo={updateHotpleInfo} isSearchMode={isSearchMode} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple}/>
          <BottomSheet activeMenu={activeMenu} setAuthModalPage={setAuthModalPage} account={account} myHotples={myHotples} mapHotples={mapHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} isSearchMode={isSearchMode} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
          <NavigationBar activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
      </MobileView>
      {authModalPage!=0 && <AuthModal authModalPage={authModalPage} setAuthModalPage={setAuthModalPage} getMyInfo={getMyInfo} account={account} setAccount={setAccount}/>}
      {userModalPage!=0 && <UserModal userModalPage={userModalPage} setUserModalPage={setUserModalPage} modalProps={modalProps} deleteMyHotple={deleteMyHotple}/>}
      {hotpleModalPage!=0 && <HotpleModal hotpleModalPage={hotpleModalPage} setHotpleModalPage={setHotpleModalPage} modalProps={modalProps} getMyHotple={getMyHotple}/>}
    </div>
  );
};


export default MainFrame;