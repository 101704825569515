import styles from './NavigationBar.module.css';


const NavigationBar = ({activeMenu, setActiveMenu}) => {
  	return (
		<div className={styles.tabBarglobal}>
			<div className={`${styles.div} ${activeMenu === 'nearby' ? styles.active : ''}`}
				onClick={() => setActiveMenu('nearby')}
			>
				<div className={styles.content}>
					<div className={styles.frame}>
							<img className={styles.icon} alt="" src="images/search.svg" />
					</div>
					<div className={styles.div1}>주변핫플</div>
				</div>
			</div>
			<div className={`${styles.div2} ${activeMenu === 'my' ? styles.active : ''}`}
				onClick={() => setActiveMenu('my')}
			>
				<div className={styles.content}>
					<div className={styles.frame}>
							<img className={styles.icon} alt="" src="images/like_outlined.svg" />
					</div>
					<div className={styles.div1}>내 핫플</div>
				</div>
			</div>
		</div>
	);
};

export default NavigationBar;
