import styles from './Menu.module.css';

const Menu = ({activeMenu, setActiveMenu}) => {
  return (
    <div className={styles.menu}>
      <div className={styles.logo}>
        <img className={styles.iconfire} alt="" src="images/fire.svg" />
      </div>
      <div className={styles.buttons}>
        <div
          className={`${styles.button} ${activeMenu === 'nearby' ? styles.active : ''}`}
          onClick={() => setActiveMenu('nearby')}
        >
          <img className={styles.icon} alt="" src="images/search.svg" />
          <div className={styles.div}>주변핫플</div>
        </div>
        <div
          className={`${styles.button1} ${activeMenu === 'my' ? styles.active : ''}`}
          onClick={() => setActiveMenu('my')}
        >
          <img className={styles.icon} alt="" src="images/like_outlined.svg" />
          <div className={styles.div1}>내 핫플</div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
